body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  z-index: 1;
}

h1 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

h2 {
  font-family: "Source sans pro", sans-serif;
  font-weight: 300;
}

h3 {
  font-family: "Source sans pro", sans-serif;
  font-weight: 300;
}

h4 {
  font-family: "Source sans pro", sans-serif;
  font-weight: 300;
}

h5 {
  font-family: "Source sans pro", sans-serif;
  font-weight: 200;
}

p {
  margin-right: 15px;
  font-family: "Source sans pro", sans-serif;
  font-weight: 200;
  font-size: 18px;
}

a:link,
a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  color: rgb(184, 184, 184);
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  left: 0;
  right: 0;
  position: fixed;
  padding: 10px 50px 10px 50px;
  z-index: 10;
}

.nav__links {
  display: flex;
  justify-content: space-between;
  flex-basis: 600px;
}

.nav__links h4 {
  font-weight: 400;
}

.header {
  position: relative;
  place-items: center;
  box-shadow: 0px 8px 41px -3px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.header img {
  object-fit: cover;
  object-position: 44% 37%;
  width: 100%;
  height: 100%;
}

.header_text {
  z-index: 2;
  will-change: opacity, transform;
  position: absolute;
  right: 5vh;
}

.header_main_text {
  position: relative;
  z-index: 1;
  text-align: right;
  white-space: nowrap;
}

.header_sub_text {
  position: relative;
  z-index: 1;
  text-align: right;
}

.header_slogan {
  position: relative;
  z-index: 1;
  text-align: right;
}

.subheader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-image: url("./media/jpg/bgr_1.png");
  background-repeat: no-repeat;
  background-position: -67% 157%;
}

.subheader__content {
  width: 100%;
  height: 100%;
  text-align: justify;
}

.references {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.references__content {
  width: 100%;
  height: 100%;
}

.reference_see_more {
  float: right;
}

.team {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-image: url("./media/jpg/bgr_2.png");
  background-repeat: no-repeat;
  background-position: -25% 118%;
}

.team__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.portrait {
  z-index: 2;
  margin-top: 150px;
}

.team img {
  flex: 0 1 150px;
  margin: 5px;
  z-index: 1;
  margin-left: 50px;
  margin-right: 50px;
}

.team svg {
  margin-right: 10px;
}

.infos {
  position: relative;
  top: -120px;
  margin-left: 60px;
  z-index: 3px;
}

.mail,
.phone {
  display: flex;
  margin: 10px 0 0 30px;
}

.mail h3,
.phone h3 {
  margin: -5px 0 8px 0;
}

.name {
  font-size: 50px;
}

.position,
details {
  margin-left: 30px;
}

.footer {
  position: relative;
  bottom: 0;
  height: 250px;
  display: grid;
  place-items: center;
  z-index: 0;
}

.footer__top {
  position: absolute;
  width: 90%;
  height: 72%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__left {
  display: flex;
  justify-content: space-between;
}

.footer__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.footer__right .top {
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  background-color: #415a77;
}

.top:hover {
  background-color: #364a61;
}

.footer__right p {
  margin-right: 15px;
  font-family: "Source sans pro", sans-serif;
  font-weight: 200;
  font-size: 18px;
}

.footer__left div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 25px;
}

.footer__left svg {
  margin-right: 15px;
}

.footer__bottom {
  position: absolute;
  width: 90%;
  height: 32%;
  margin: 0 50px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.footer__bottom p {
  margin-right: 15px;
  font-family: "Source sans pro", sans-serif;
  font-weight: 200;
  font-size: 15px;
}

.footer__bottom img {
  height: 20px;
}

.footer__impressum {
  display: flex;
}

.offset {
  height: 100px;
}

.gallery {
  z-index: 2;
  position: relative;
  display: grid;
  grid-gap: 50px;
}

.leistungen {
  z-index: 2;
  position: relative;
  display: grid;
  grid-gap: 50px;
}

.leistungen img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.awssld {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 575ms;
  --organic-arrow-thickness: 6px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 21px;
  --organic-arrow-color: #1a80a2;
  --control-button-width: 11%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #5ed6ff;
  --control-bullet-active-color: #50b6d9;
  --loader-bar-color: #030303;
  --loader-bar-height: 1px;
}

.small_header {
  height: 600px;
  padding-top: 50px;
}

.small_header img {
  object-fit: cover;
  object-position: 44% 37%;
  width: 100%;
  height: 100%;
}

.small_header_main_text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  top: 150px;
}

.impressum {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.impressum__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.plakat_wrapper {
  text-align: center;
}

.plakat_wrapper > img {
  margin-top: 20px;
  height: 690px;
}

@media screen and (min-width: 1201px) {
  h1 {
    font-size: 120px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
  }

  nav {
    height: 80px;
  }

  .nav__logo {
    height: 45px;
    width: auto;
  }

  .header {
    height: 100vh;
  }

  .header_text {
    margin-top: 35vh;
  }

  .header_main_text {
    margin-bottom: 0px;
  }

  .subheader__content {
    margin: 100px;
  }

  .references__content {
    margin: 100px;
  }

  .team__content {
    margin: 100px;
  }

  .team img {
    height: 350px;
    width: auto;
  }

  .portrait {
    margin-top: 150px;
  }

  .skills__small {
    display: none;
  }

  .skills {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .skills__content {
    margin: 50px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 3fr 1px 3fr 1px 3fr;
    z-index: 2;
    position: relative;
  }

  .skill {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-self: center;
    align-items: center;
    margin-top: 200px;
  }

  .skill img {
    height: 70px;
    width: auto;
  }

  .line {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    height: 80%;
    justify-self: center;
    align-self: center;
  }

  .skill_desc_vis {
    opacity: 1;
    transition: 0.5s all;
    text-align: justify;
  }

  .skill_desc_invis {
    opacity: 0;
    transition: 0.1s all;
    text-align: justify;
  }

  .bm-burger-button {
    display: none;
  }

  .footer__top {
    margin: 0 50px;
  }

  .gallery {
    margin: 100px;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  }

  .leistungen {
    margin: 100px;
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  }

  .impressum__content {
    margin: 100px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 666px) {
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
  }

  nav {
    height: 80px;
  }

  .nav__logo {
    height: 40px;
    width: auto;
  }

  .header {
    height: 100vh;
  }

  .header_text {
    margin-top: 35vh;
  }

  .header_main_text {
    margin-bottom: -20px;
  }

  .subheader__content {
    margin: 50px;
  }

  .references__content {
    margin: 50px;
  }

  .team__content {
    margin: 50px;
  }

  .team img {
    height: 350px;
    width: auto;
  }

  .portrait {
    margin-top: 150px;
  }

  .nav__links {
    display: none;
  }

  .skills {
    display: none;
  }

  .skills__small {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .skills__content {
    margin: 50px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 3fr 1px 3fr 1px 3fr;
    z-index: 2;
    position: relative;
  }

  .skill {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-self: center;
    align-items: center;
  }

  .skill img {
    height: 70px;
    width: auto;
  }

  .line {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    width: 80%;
    justify-self: center;
    align-self: center;
  }

  .skill_desc_vis {
    opacity: 1;
    transition: 0.5s all;
    text-align: justify;
  }

  .skill_desc_invis {
    opacity: 0;
    transition: 0.1s all;
    text-align: justify;
  }

  .team__content {
    flex-wrap: wrap;
  }

  .portrait:last-child {
    margin-top: 0;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 35px;
    width: 35px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  .bm-cross span {
    height: 28px;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em;
    display: contents;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    font-size: 30px;
    margin-top: 10px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .footer {
    height: auto;
  }

  .footer__left {
    flex-direction: column;
    width: 50%;
  }

  .footer__right {
    align-items: baseline;
  }

  .footer__top {
    position: relative;
  }

  .footer__bottom {
    position: relative;
    margin-top: 50px;
    justify-content: center;
  }

  .gallery {
    margin: 50px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .leistungen {
    margin: 50px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .impressum__content {
    margin: 50px;
  }
}

@media screen and (max-width: 665px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
  }

  nav {
    height: 50px;
    padding: 10px 25px 10px 25px;
  }

  .nav__logo {
    height: 35px;
    width: auto;
  }

  .header {
    height: 100vh;
  }

  .header_text {
    margin-top: 35vh;
  }

  .subheader__content {
    margin: 30px;
    text-align: left;
  }

  .small_header {
    height: 350px;
  }

  .references__content {
    margin: 30px;
  }

  .team__content {
    margin: 30px;
  }

  .team img {
    height: 250px;
    width: auto;
  }

  .portrait {
    margin-top: 150px;
  }

  .nav__links {
    display: none;
  }

  .skills {
    display: none;
  }

  .skills__small {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .skills__content {
    margin: 30px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 3fr 1px 3fr 1px 3fr;
    z-index: 2;
    position: relative;
  }

  .skill {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-self: center;
    align-items: center;
  }

  .skill img {
    height: 70px;
    width: auto;
  }

  .line {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    width: 80%;
    justify-self: center;
    align-self: center;
  }

  .skill_desc_vis {
    opacity: 1;
    transition: 0.5s all;
    text-align: justify;
  }

  .skill_desc_invis {
    opacity: 0;
    transition: 0.1s all;
    text-align: justify;
  }

  .team__content {
    flex-wrap: wrap;
  }

  .portrait:last-child {
    margin-top: 0;
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 22px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 35px;
    width: 35px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  .bm-cross span {
    height: 28px;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em;
    display: contents;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    font-size: 30px;
    margin-top: 10px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .footer {
    height: auto;
  }

  .footer__left {
    flex-direction: column;
    width: 100%;
  }

  .footer__left div {
    margin-top: -30px;
  }

  .footer__right {
    width: 100%;
    justify-content: space-between;
  }

  .footer__top {
    position: relative;
    flex-direction: column;
  }

  .footer__bottom {
    position: relative;
    margin-top: 100px;
    justify-content: center;
  }

  .footer__bottom > .footer__right {
    flex-direction: row;
    align-items: baseline;
  }

  .gallery {
    margin: 30px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .leistungen {
    margin: 30px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .impressum__content {
    margin: 30px;
  }
}

@media screen and (max-height: 700px) {
  .header_text {
    margin-top: 15vh;
  }

  nav {
    height: 50px;
    padding: 10px 25px 10px 25px;
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 22px;
  }
}
